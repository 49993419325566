<template>
    <div class="content-box">
        <el-card class="my-20">
            <div class="d-flex-center">
                <div class="d-inline-flex-center is-link" @click="$nav.back()">
                    <i class="icon icon-back my-8 mr-8" />
                    <span class="color-666">{{ $t('back') }}</span>
                </div>
                <div class="font-16 ml-20 pl-20 mr-auto font-bold color-666 border-left">{{ $t('news.title') }}</div>
                <el-button type="primary" icon="el-icon-refresh" plain @click="fatchList()">{{ $t('refresh') }}</el-button>
            </div>
        </el-card>
        <div v-loading="loading" class="bg-white border-r box-shadow">
            <div
                v-for="(item, index) in list" :key="index"
                class="p-20 is-link menu-item d-flex-center"
                :class="{ active: $route.params.id == item.id }"
                @click="$nav.push('/notice/details/'+item.id)"
            >
                <div class="mr-auto">
                    <h3 class="my-10" v-text="item.title" />
                    <p class="my-10" v-text="item.create_time" />
                </div>
                <i class="ml-20 el-icon-arrow-right" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            // active: 0
            list: [],
            loading: false,
            query: {}
        }
    },
    watch: {
        $route: function (to, from){
            if (to.params.id == from.params.id) return
            this.fatchData()
        }
    },
    created: function () {
        this.fatchList()
    },
    methods: {
        fatchList: async function (){
            this.loading = true
            const { data } = await this.$request.post('notice', this.query)
            this.list = data.data
            this.loading = false
        },
    }
}
</script>
<style lang="scss" scoped>
.menu-item{
    border-left: 4px solid transparent;
    border-bottom: 1px solid #eceff0;
}
.menu-item.active,
.menu-item:hover{
    border-left: 4px solid $--color-primary;
    color: $--color-primary;
}
.des-box{
    line-height: 22px;
}
</style>